import styled, { css } from 'styled-components'
import { TProps } from './types'

export const ButtonContainer = styled.button<TProps>`
  background-color: ${props => props.theme.buttonDefaultColor};
  color: ${props => props.theme.secondaryTextColor};
  height: 46px;
  border-radius: 24px;
  font-family: 'Univers', Helvetica, Arial, sans-serif;
  font-size: 18px;
  border: none;
  width: 100%;
  max-width: 340px;
  line-height: 1;
  font-weight: 700;
  cursor: pointer;
  transition: all .3s;
  text-transform: uppercase;
  padding-top: 5px;

  &:active,
  &:hover {
    opacity: 0.6;
  }

  ${props => props.disabled && css`
    cursor: not-allowed;
    background-color: ${props => props.theme.blankColor};
    color: ${props => props.theme.buttonDefaultColor};
    border: 1px solid ${props => props.theme.buttonDefaultColor};
    opacity: 0.6;
  `}

  ${props => props.appearance === 'inverted' && css`
    background-color: ${props => props.theme.blankColor};
    color: ${props => props.theme.buttonDefaultColor};
    border: 1px solid ${props => props.theme.buttonDefaultColor};

    &:active,
    &:hover {
      opacity: 0.6;
    }
  `}
`
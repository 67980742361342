import styled from 'styled-components'
import { Button } from 'components'

export const AppButton = styled(Button)`
  margin-bottom: 16px;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  max-width: 186px;
  font-family: 'Univers', Helvetica, Arial, sans-serif;
  margin: 0 auto 16px;
  text-transform: uppercase;
  color: ${props => props.theme.primaryTextColor};
`
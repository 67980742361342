import { FC, useState } from 'react'
import serverApi from 'api'
import { AxiosError } from 'axios'
import {
  Title,
  AppButton,
  AppInput,
} from './styled-components'

const EnterCode: FC = () => {
  const [ code, setCode ] = useState<string>('')

  const onSubmit = async () => {
    const codePrepared = code.replace(/-/g, "").toLocaleLowerCase()
    try {
      const response = await serverApi.getLink(codePrepared)
      if (response && response.data) {
        const { linkdroplink } = response.data
        window.location.href = linkdroplink
      }
    } catch (err) {
      const e = err as AxiosError
      alert(e.message)
    }    
  }

  return <>
    <Title>
      Enter the code to claim
      <br />your NFT
    </Title>
    <AppInput
      value={code}
      placeholder='Passcode'
      onChange={(value) => setCode(value)}
    />
    <AppButton
      title='Continue'
      disabled={!code || code.length !== 8}
      onClick={() => {
        onSubmit()
      }}
    />
  </>
}

export default EnterCode
import styled from 'styled-components'

export const InputContainer = styled.input`
  height: 44px;
  background: ${props => props.theme.blankColor};
  border: none;
  font-size: 18px;
  border-bottom: 1px solid ${props => props.theme.primaryTextColor};
  color: ${props => props.theme.primaryTextColor};
  border-radius: 0px;
  line-height: 1;
  padding: 10px;
  font-family: 'Univers', Helvetica, Arial, sans-serif;
  width: 100%;
  max-width: 340px;
  text-transform: uppercase;
  outline: none;

  &::placeholder {
    color: ${props => props.theme.additionalTextColor};
    opacity: 1;
  }
`
import { FC } from 'react'
import {
  Title,
  AppButton,
} from './styled-components'
import TProps from './types'

const WalletCheck: FC<TProps> = ({
  onAccept,
  onDecline
}) => {
  return <>
    <Title>
      Do you have a crypto wallet?
    </Title>
    <AppButton
      title='Yes'
      onClick={onAccept}
    />
    <AppButton
      title='No'
      appearance='inverted'
      onClick={onDecline}
    />
  </>
}

export default WalletCheck
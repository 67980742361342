import styled from "styled-components"
import { Button } from 'components'

export const Overlay = styled.div`
  height: 100vh;
  background-color: ${props => props.theme.blankColor};
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 50px 20px 40px;

`

export const Title = styled.h2`
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'Univers', Helvetica, Arial, sans-serif;
  color: ${props => props.theme.primaryTextColor};
  text-align: center;
  margin-bottom: 0 0 40px;
`

export const Subtitle = styled.h2`
  font-size: 18px;
  text-transform: uppercase;
  color: ${props => props.theme.primaryTextColor};
  text-align: center;
  margin-bottom: 0 0 16px;
`

export const Text =  styled.p`
  font-size: 18px;
  color: ${props => props.theme.primaryTextColor};
  text-align: center;
  margin: 0 auto 40px;
  max-width: 260px;

  a {
    color: ${props => props.theme.primaryTextColor};

    &:hover {
      opacity: 0.6;
    }
  }
`

export const Content = styled.div`
  border-top: 1px solid ${props => props.theme.primaryBorderColor};
  position: relative;
`

export const Fading = styled.div`
  height: 80px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const Texts = styled.div`
  height: 100vh;
  max-height: 400px;
  padding: 40px 15px 30px;
  overflow-y: scroll;
  margin-bottom: 16px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.primaryBorderColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.primaryBorderColor};
  }
`

export const ButtonStyled = styled(Button)`
  margin: 0 auto;
  display: block;
`
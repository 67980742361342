import { FC } from 'react'
import {
  Overlay,
  Title,
  Content,
  Subtitle,
  Text,
  Fading,
  Texts,
  ButtonStyled
} from './styled-components'
import { TProps } from './types'

const Instruction: FC<TProps> = ({
  onClose
}) => {
  return <Overlay>
    <Title>How to set up a Metamask wallet</Title>
    <Content>
      <Texts>
        <Subtitle>
          01
        </Subtitle>
        <Text>
          Go to Metamask’s <a href="https://metamask.io/" target="_blank" rel="noreferrer">official website</a>, choose any of the supported browsers, IOS or Android and click on “Install”. 
        </Text>
        <Subtitle>
          02
        </Subtitle>
        <Text>
          Download the App and open it. The MetaMask extension icon will be on the top right corner of the browser.
        </Text>

        <Subtitle>
          03
        </Subtitle>
        <Text>
          Click on “Create a New Wallet”.
        </Text>

        <Subtitle>
          04
        </Subtitle>
        <Text>
          MetaMask will now show you a 12-word recovery key (Seed). Follow the seed phrase instructions on your screen. Click on “Continue”.
        </Text>

        <Subtitle>
          05
        </Subtitle>
        <Text>
          Congratulations! That’s it you’ve just created a new Ethereum address using MetaMask.
        </Text>

        <Subtitle>
          06
        </Subtitle>
        <Text>
          In case of any trouble check our <a href="https://xmas.alphemy.capital/#faq" target="_blank" rel="noreferrer">website’s FAQ</a>.
        </Text>
      </Texts>
      
      <Fading />
    </Content>
    <ButtonStyled
      title='Claim an NFT'
      appearance='inverted'
      onClick={onClose}
    />
  </Overlay>
}

export default Instruction
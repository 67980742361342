import styled from 'styled-components'
import { Button, Input } from 'components'

export const AppButton = styled(Button)`
  margin-bottom: 40px;
`

export const AppInput = styled(Input)`
  margin-bottom: 30px;
`

export const Title = styled.div`
  font-family: 'Univers', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin: 0 0 16px;
  text-transform: uppercase;
  color: ${props => props.theme.primaryTextColor};
`

import { FC, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import themes from 'themes'
import {
  Container,
  HeaderLogo,
  AppFooter,
  AppHeader,
  Content,
  FooterText
} from './styled-components'
import BrandIcon from 'images/alphemy.png'
import { EnterCode, WalletCheck, Instruction } from './components'
import { TScreens } from 'types'

const App: FC = () => {
  const [ screen, setScreen ] = useState<TScreens>('wallet-check')
  const [ instruction, setInstruction ] = useState<boolean>(false)

  const defineContent = () => {
    switch (screen) {
      case 'wallet-check':
        return <WalletCheck
          onAccept={() => setScreen('enter-code')}
          onDecline={() => setInstruction(true)}
        />
      case 'enter-code':
        return <EnterCode />
      default:
        return null
    }
  }

  return <ThemeProvider theme={themes.light}>
    <Container>
      {instruction && <Instruction onClose={() => setInstruction(false)} />}
      <AppHeader>
        <HeaderLogo src={BrandIcon} />
      </AppHeader>
      <Content>
        {defineContent()}
      </Content>
      <AppFooter>
        <a
          rel="noreferrer"
          target="_blank"
          href='https://linkdrop.io/'
        >
          Powered by LinkDrop
        </a>
        {screen === 'enter-code' && !instruction && <FooterText>
          The eight-digit code you can find in our first email to you with congratulations!
        </FooterText>}
      </AppFooter>
    </Container>
  </ThemeProvider>
}

export default App;

import styled from 'styled-components'
import { Header, Footer } from 'components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.blankColor};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 70px;
`
export const FooterText = styled.div`
  max-width: 230px;
  margin-top: 40px;
  font-size: 18px;
  color: ${props => props.theme.primaryTextColor};
`

export const Content = styled.div`
  max-width: 340px;
  width: 100%;
  text-align: center;
`

export const AppHeader = styled(Header)`
  margin-bottom: 70px;
`

export const AppFooter = styled(Footer)`
  margin-bottom: 70px;
  text-align: center;

  a {
    color: ${props => props.theme.primaryTextColor};
    opacity: .4;
    font-size: 20px;
    text-decoration: none;
  }
`

export const HeaderLogo = styled.img`
  max-width: 110px;
`

export const FooterLogo = styled.img`
  max-width: 155px;
`
